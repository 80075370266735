
.navbar {
    padding: 0.5rem 1rem;
  }
  
  .logo {
    width: 100%;
    height: 71px;
    position: relative;
    /* top: -7px; */
    left: 40px;
    background-color: transparent;
    background: none;
  }
  
  .navbar-nav .nav-link {
    margin-left: 1rem;
  }
  
  .navbar-nav .nav-link.active {
    color: #1754d2; 
  }
  
  .user-avatar {
    width: 30px;
    height: 30px;
    margin-right: 0ch;
  }

  .navbar-toggler{
    border: 2px solid #1754d2 !important;
    border-radius: 5px !important;
  }
  .navbar-collapse {
    display: block;
    justify-content: flex-end; 
    padding-right: 1rem; 
  }
  
  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  
  }
  
  .dropdown-menu {
    display: none;
    position: absolute; 
    top: 100%; 
    left: 0;
    z-index: 1000; 
  }
  
  .dropdown-menu a {
    color: #000;
  }
  
  .dropdown-menu a:hover {
    /* background-color: #89a6c3;  */
    background: #208f6b;
    color: #fff;
  }
  

  .search-and-category {
    display: flex;          
    flex-direction: column;  
    gap: 16px;               
  }
  
  .dropdown-container {
    display: flex;         
    justify-content: flex-start; 
  }
  
  .category-dropdown {
    padding: 8px;           
    border: 1px solid #ccc; 
    border-radius: 4px;   
    font-size: 16px;        
    width: 100%;            
    max-width: 300px;      
  }
  .homePage{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .input-group {
    display: flex;           /* Use flexbox for input and icon alignment */
    align-items: center;     /* Center items vertically */
  }
  
  .search-icon {
    position: absolute;
      right: 20px;
      top: 18px;
      transform: translateY(-50%);
      /* pointer-events: none; */
      color: #1a367d;
  }
  
  .search-input {
  width: 100%;
  padding-left: 40px; /* Adjust padding to create space for the icon */
}
