@media screen and (min-width: 600px) {

    /* If screen size is more than 600px wide, set the font-size of <div> to 80px */
    .textStyle {
        width: 100%;
    }

    .toggleMenu {
        position: relative;
        left: -21px;
    }
    .css-1o4d9vs {
        padding: 0px !important;
    }
    .user-avatar {
        margin-left: 3px;
        /* height: 10px;
        width: 10px; */
    }

    .dropdown-menu.dropMenu {
        margin-left: 12px;
    }

    .textStyle {
        font-size: 30px;
        width: 100%;
    }

    .courseBg {
        /* height: 595px; */
        width: 100%;

    }
    .css-rhsghgP {
        object-fit: fill !important;
    }

    .bannerRight {
        right: 0;
    }

    .horizontal {
        width: auto;
    }

    .video-container {
        /* width: 100%; */
    }

    .banner-content {
        width: 100%;
    }
    /* .profile-card{
        width: 100%;
    } */
}

@media screen and (max-width: 600px) {

    /* If screen size is 600px wide, or less, set the font-size of <div> to 30px */
    .custom-content h1 {
        font-size: 30px;
    }
    .custom-content h2 {
        font-size: 30px;
    }
    .custom-content li {
        font-size: 15px;

    }
    .custom-content h4 {
        font-size: 20px;
    }
    .accord-toggle{
        width: 100%;
       }
    marquee {
        font-size: 16px !important;
    }
    .mb-4{
        margin-bottom: 4.5rem!important;
    }
    .courseBox{
        margin-top: 50px;
    }
    .posts-grid {
        display: block;
    }
    .text-center {
        font-size: 20px !important;
    }
    .textStyle {
        font-size: 20px;
        width: 100%;
        margin-bottom: 0px !important;

    }
    .complete-purchase-button{
        width: 100% !important;
        left: 0%;
        position: relative;
    }
    .phone-number-section input{
        width: 100%; 
    }
    .css-bpb9n0{
        margin-top:0px;
        padding: 0px;
    }
    .card{
        height: 395px !important;
      }
    .css-1g8fryd{
        width: 100% !important;
    }
    .toggleMenu {
        position: relative;
        left: -21px;
    }

    .css-ahj2mt-MuiTypography-root {
        font-size: 13px !important;
        font-family: monospace !important;

    }
    .profile-card{
        width: 75%;
        font-size: 12px;
    }

    .horizontal {
        width: auto;
    }

    .user-avatar {
        margin-left: 3px;
        height: 10px;
        width: 10px;
    }

    .bannerLeft {
        width: 100%;
    }

    .btnSign {
        width: 100%;
    }

    .courseBg {
        height: 800px;
        width: 100%;
    }

    .bannerRight {
        right: 0;
        top: 500px;
    }

    .search-and-category {
        margin: 0px auto;
        width: 95%;
    }

    .category-dropdown {
        max-width: 100%;
    }

    .Box {
        width: 100% !important;
        font-family: monospace !important;
        /* margin-top: 150px; */
    }

    h4,
    ul,
    li {
        font-family: monospace !important;
    }

    .accord {
        width: 100% !important;
    }

    .imgRight {
        height: auto;
        margin-top: 20px;
    }

    .contactPage {
        height: auto;
    }

    .img-fluid {
        height: auto !important;
    }

    .form-control {
        width: 100% !important;
    }

    .loginImageLeft {
        height: auto;
    }

    .css-fepk1l-MuiGrid-root {
        font-size: 12px !important;
    }

    .inst-dashboard-jumbotron {
        font-size: 18px;
        margin-top: 0px;
    }

    .css-zow5z4-MuiGrid-root>.MuiGrid-item {
        padding: 0px 0px;

    }

    .pull-left>h5 {
        font-size: 25px;
    }

    .pull-left>p {
        font-size: 14px;

    }

    .css-h93ljk-MuiTypography-root {
        font-size: 13px !important;
    }

    .nav-link {
        margin-left: -11px;
        font-size: 9px !important;
    }

    .dropdown-item {
        font-size: 14px !important;
    }

    .texts h3 {
        font-size: 16px;
    }

    .signUp {
        text-align: center;
        margin-top: 20px;
    }

    .LoginPage {
        text-align: center;
        margin-top: 20px;
    }

    .btnForgot {
        width: 100%;
    }

    .text-light {
        width: 100%;
        text-align: left;
    }

    .sidebar {
        width: 100%;
        height: auto !important;
        padding-bottom: 30px;
    }

    .sidebar-content {
        height: auto !important;
    }

    .css-9atyyu-MuiBadge-root .MuiBadge-badge {
        position: absolute;
        right: 0;
        top: 64px !important;
    }

    .css-2ulfj5-MuiTypography-root {
        font-size: 17px !important;
        font-family: monospace !important;

    }

    .logo {
        height: 50px;
    }

    .css-37jdci-MuiTypography-root {
        font-size: 13px !important;
    }

    .input-group .btn {
        color: #11367d;
        width: 64px;
        background: #11367d;
    }

    .input-group {
        padding-bottom: 0px;
        right: 0;
        width: 95%;
        margin: 8px auto;
    }

    .css-dmmspl-MuiFormGroup-root {
        display: block !important;
        flex-direction: column !important;
    }

    .css-j204z7-MuiFormControlLabel-root {
        margin: 0px auto;
    }

    .css-zow5z4-MuiGrid-root>.MuiGrid-item {
        padding-bottom: 40px;
    }

    .nav-item.dropdown:hover .dropdown-menu {
        margin-left: 22px;
    }

    /* If screen size is more than 600px wide, set the font-size of <div> to 80px */
    .textStyle {
        width: 100%;
    }

    .toggleMenu {
        position: relative;
        left: -21px;
    }

    .user-avatar {
        margin-left: 3px;
        height: 10px;
        width: 10px;
    }

    marquee {
        font-size: 14px;
    }

    .dropdown-menu.dropMenu {
        margin-left: 12px;
    }

    .textStyle {
        font-size: 30px;
        width: 100%;
    }

    .courseBg {
        /* height: 595px; */
        width: 100%;


    }

    .bannerRight {
        right: 0;
    }

    .horizontal {
        width: auto;
    }

    .video-container {
        width: 100%;
    }

    .enroll-container {
        width: 94.3% !important;
    }

    .banner-content {
        width: 100%;
    }
    .userBox{
        margin: 0px auto;
        width: 100%;
      }
      .commentBox{
        width: 100%;
      }
}

@media only screen and (min-width: 768px) and (max-width: 1028px) {
    .input-group {
        margin-left: 0px;
    }

    .form-control {
        width: 240px !important;
    }

    .btnSign {
        width: 240px;
    }

    .text-center {
        font-size: 20px !important;
    }

    .box {
        height: 60px;
    }

    .typo {
        font-size: 15px !important;
    }

    .css-bny0f1-MuiCardContent-root {
        padding: 2px !important;
        height: 300px !important;
    }

    .courseRight {
        color: #000;
        margin-right: 0px;
        position: relative;
        padding-right: 0px;
        font-size: 14px !important;
    }

    .css-9atyyu-MuiBadge-root .MuiBadge-badge {
        top: 52px !important;
    }

    
    .css-1o4d9vs {
        padding: 0px !important;
    }
    .custom-button{
        width: 90%;
    }
    .buttonCourse .MuiButton-root{
    left: 0px;
     width: 100%; 
     font-size: 15px;
    }
    /* .thumbnail img{
        width: auto;
    } */
    .arrowUp{
        position: absolute;
        right: 0;
    }
    .arrowDown{
        position: absolute;
        right: 0;
    }
    .css-ahj2mt-MuiTypography-root {
        font-size: 12px !important;
        text-align: left;
    }
    .css-10hburv-MuiTypography-root{
        font-size: 1rem !important;
    }

}

@media only screen and (max-width: 768px) {
    /* .arrow {
        display: none;
    } */

    /* .css-1o4d9vs {
        padding: 8px !important;
    } */

    .css-rhsghgP {
        object-fit: fill !important;
    }

    .css-oq3fqg .MuiBadge-badge {
        top: 66px !important;
    }
}
    @media only screen and (min-width: 768px){
        .card {
            height: 435px !important;
        }
    }
        
        /* @media only screen and (max-width: 1028px){
            .enroll-container {
                width: 93%;
            } */
    

