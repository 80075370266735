* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 0;
  font-family: monospace !important;
  min-height: 100%;
  position: relative;
  overflow-x: hidden !important;
}

h2 {
  margin-bottom: 52px;
  font-size: 35px;
  color: #11367d;
  text-transform: uppercase;
}
.content{
  background-image: url('https://course-image-data.s3.ap-south-1.amazonaws.com/website/course-banner.png');
  background-size: cover;  /* This ensures the image covers the element */
  background-position: center; /* This centers the image */
  background-repeat: no-repeat; /* This prevents the image from repeating */
  height: 50vh;
}

.btnForgot {
  display: flex;
  margin: 0px auto;
  text-align: center;
  background: #11367d !important;
  border: 1px solid #11367d;
}

.input-group .btn {
  color: #11367d;
  width: 64px;
  background: #11367d;
}

.input-group {
  width: 42%;
}

.form-control:focus {
  border-color: #1a367d;
  box-shadow: 0 0 0 .25rem rgb(22 110 241 / 25%);
}

.btn:first-child:active {
  background: #000;
}

.texts {
  padding: 20px;
}

.texts h2 {
  font-size: 30px;
  color: #11367d;
  text-transform: capitalize;
  white-space: pre-wrap;
  overflow: hidden;
  word-wrap: break-word;
  width: 300px;
  text-overflow: ellipsis;
  height: 140px;
}

.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-bottom: 40px;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  border: .5px solid #e8e8e8 !important;
}

.contactPage {
  width: 100%;
  /* height: 500px; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.pull-left>h5 {
  font-size: 37px;
  color: #000;
  margin-bottom: 30px;
}

.pull-left>p {
  margin-right: 44px;
  font-size: 16px;
  text-align: justify;
  color: #6c757d;
}

.form-control {
  width: 500px !important;
}

.btnLogin {
  width: 100%;
  cursor: pointer;
  background-color: #11367d !important;
  color: #fff !important;
}

.btnSign {
  width: 500px;
  cursor: pointer;
  background-color: #11367d !important;
  color: #fff !important;
}

.imgRight {
  width: 100%;
  height: 550px;
  margin-top: 80px;
}

.loginImageLeft {
  width: 100%;
  height: 600px;
}

.aboutLeft {
  float: left;
  clear: both;
}

.aboutRight {
  float: right;
}

.imgCourse {
  padding: 0px 0px;
  border-bottom: .002px solid #efe9e9
}

.lesson {
  /* float: right; */
  font-size: 16px;
  color: #11367d;
}

.square {
  border-radius: 0px;
  background: #e2fae6;
}

.banner-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Prevents content from overflowing */
}

.bannerImg {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin-bottom: 45px;
  /* height: 430px; */

}

marquee {
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  /* background-color: rgba(154, 118, 118, 0.5); */
  color: #208f6b;
  padding: 10px;
  box-sizing: border-box;
  font-size: 30px !important;
  z-index: 100;
}

.css-ag7rrr-MuiTypography-root {
  /* font-size: 26px !important; */
  font-weight: 700 !important;
  color: #1e916a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 20px;
}

.css-1ry679h-MuiTypography-root {
  font-size: 14px !important;
  padding-bottom: 14px;
  color: #11367d;
  font-weight: 600 !;
}

.textStyle {
  border-bottom: .1px solid #20906c;
  max-width: max-content;
  margin: 0 auto;
  margin-bottom: 50px;
}

/* .css-o69gx8-MuiCardMedia-root {
  object-fit: fill !important;
  transition: ease 1s;  
}
.css-o69gx8-MuiCardMedia-root :hover{
  background:#000 !important;
} */
 .accord-toggle{
  width: 70%;
 }
 
.userBox{
  margin: 0px auto;
  width: 616px;
}
.css-1udzrnu-MuiCardContent-root:last-child {
   padding-bottom: 0px !important; 
}
.css-o69gx8-MuiCardMedia-root {
  object-fit: cover; /* Set the default object-fit */
  transition: 1s ease; /* Apply transition to transform */
  cursor: pointer;
}

.css-o69gx8-MuiCardMedia-root:hover {
  transform: scale(1.05); /* Apply scaling effect on hover */
  background-color: #000 !important;
  cursor: pointer;
}

.css-rhsghg {
  object-fit: fill !important;

}

.card {
  height: 400px !important;
}

.cardName {
  height: 300px !important;
  width: 300px;
}

.courseBox {
  padding: 1px 17px;
  height: 300px;
  margin-top: 9px;
}

.box {
  height: 60px;
  padding: 10px;
}

.typo {
  font-size: 18px;
  font-family: monospace;
}

.css-et1ao3-MuiTypography-root {
  font-size: 12px !important;
  text-transform: unset;
  color: #208f6c;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #208f6c;
}
.search{
  color: #000;
}
.nav-link {
  color: #000 !important;
  cursor: pointer;
}

.nav-link.active {
  background-color: transparent !important;
  cursor: pointer;
  color: #11367d !important;
  font-weight: 600;
}

.nav-link:focus {
  background-color: transparent !important;
  cursor: pointer;
  color: #11367d !important;
  font-weight: bolder;

}

.nav-link:hover {
  background-color: transparent !important;
  cursor: pointer;
  color: #11367d !important;
  /* font-weight: bolder; */

}

.MuiTouchRipple-root .css-8je8zh-MuiTouchRipple-root {
  background-color: transparent !important;
}

.css-qyxyi3-MuiButtonBase-root-MuiListItem-root:focus {
  background-color: transparent !important;
  cursor: pointer;
  color: #11367d !important;
}

.css-qyxyi3-MuiButtonBase-root-MuiListItem-root:hover {
  background-color: transparent !important;
  cursor: pointer;
  color: #11367d !important;
}

.css-qyxyi3-MuiButtonBase-root-MuiListItem-root:active {
  background-color: transparent !important;
  cursor: pointer;
  color: #11367d !important;
}

.css-qyxyi3-MuiButtonBase-root-MuiListItem-root:visited {
  background-color: rgb(235 236 237 / 0%) !important;
  cursor: pointer;
  color: #11367d;
}

a {
  text-decoration: none;
}

.css-1y2lbxp-MuiBadge-root .MuiBadge-badge {
  padding: 18px 17px !important;
  width: 75px !important;
}

.jumbotron {

  color: #fff;
  height: auto;
}

.bannerRight {
  position: absolute;
  right: 0;
  top: 145px;
}

.text-light {
  width: 100%;
  text-align: left;
}

.bannerLeft {
  /* position: absolute; */
  /* left: 0; */
  color: #e9e1e1;
  padding: 10px 20px;
  font-size: 20px;
  top: 118px;
  width: 60%;

}


.details-container {
  width: 25%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner-content {
  display: grid;
  /* background: white; */
  border-radius: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* height: 216px; */
  margin-top: 35px;
  width: 335px;
}

.courses {
  margin-top: 12px;
  margin-bottom: 50px;
  color: #1fdba0;
}

.css-zq6grw {
  margin: 0px;
  font-family: monospace !important;
  font-weight: 400;
  font-size: 1.0rem;
  line-height: 3;
  letter-spacing: 0em;
}

/* .buttonCourse {
 position: relative;
 left: -60px;

}  */
.order-summary {
  border: .5px solid #f2eeee;
  padding: 43px;
  margin-top: 50px;
}

.squares{
  background-color: rgb(178, 206, 206);
  background: #ffffff;
}

.buttonCourse .MuiButton-root {
  color: white;
  background-color: #11367d;
    /* text-transform: none; */
    /* font-weight: bold; */
    font-size: 1rem;
    box-shadow: none;
    border-radius: 9px;
    /* padding: 7px 12px; */
    /* float: left; */
    background-color: #259c6b;
    position: relative;
    width: 25%;
    padding: 3px;
    height: 60px;
    margin-top: 60px;

    text-transform: capitalize;
}
.inst-dashboard-jumbotron {
  margin-top: 0px;
  margin-bottom: 50px;
  color: #208f6c;
}

.courseRight {
  /* float: right; */
  color: #000;
  /* margin-right: 2px; */
  position: relative;
  /* padding-right: 17px;
  top: 16px; */
}

.courseBg {
  width: 100%;
  height: 398px;
  padding-bottom: 54px;
}


.topic-list h4 {
  padding-top: 30px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.css-1anx036 {
  margin: -10px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.css-zyed50-MuiGrid-root {
  margin-top: -30px;
  position: relative;
  left: 100px;
  top: 20px;
}

.custom-button {
  background-color: #11367d;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: white;
  height: 45px;
  margin-top: 30px;
  cursor: pointer;
  outline: none;
  width: 100%;
}


.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #208f6c !important;
}

.edit-button {
  display: inline-block;
  padding: 6px 12px;
  background-color: #1976d2;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.edit-button:hover {
  background-color: #115293;
}


.edit-button,
.publish-button {
  display: inline-block;
  padding: 6px 12px;
  background-color: #1976d2;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  margin-left: 8px;
}

.edit-button:hover,
.publish-button:hover {
  background-color: #115293;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.custom-button-lesson {
  width: 100px;
  min-width: 40px;
  padding: 5px;
}

.css-h4y409-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
}

.style {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: 500px;
  border: '2px solid #000';

}

;

.video-player-container {
  max-width: 220px;
  max-height: 200px;
  margin: 0 auto;
}

.video-player {
  width: 30%;
  min-height: 420px;
  max-height: 500px;
  border: .2px solid rgb(0 0 0 / 5%);
}

.video-controls {
  margin-top: 10px;
  text-align: center;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  text-transform: capitalize;
  min-height: auto;
  color: #fff;
}


.sidebar {
  width: 22%;
  /* Define a fixed width for the sidebar */
  background-color: rgb(250, 248, 248);
  /* Sidebar background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Optional shadow */
  overflow-y: auto;
  /* Allow scrolling if content exceeds the height */
  position: relative;
  /* For any positioning you might want later */
  background: #cad9d7;
  /* Background color */
  flex-shrink: 0;
  /* Prevent the sidebar from shrinking */
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: monospace !important;
  line-height: 1.6;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 2px 0;
}

.sidebar-content {
  background-color: #cddddb66;
  box-sizing: border-box;
  font-family: monospace !important;
  height: 100vh;
  margin-left: -10px;
  margin-right: -10px;
  position: sticky;
  overflow-y: scroll; /* Allows vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari and Edge */
.sidebar-content::-webkit-scrollbar {
  display: none;
}

.square {
  background: #11367d;
  color: #fff;
  padding: 10px;
}

.Test {
  margin: 20px auto;
}

.topic {
  padding: 10px;
  cursor: grab;
  color: #a59a9a;
  justify-content: space-between;
}

li:hover .topic-name {
  color: #11367d !important;
  font-weight: bold !important;
  cursor: pointer;
  background: none !important;
}

li:hover .arrow {
  color: #11367d !important;
  font-weight: bold !important;
  cursor: pointer;
}

li:active .topic-name {
  color: #11367d !important;
  cursor: pointer;
  font-weight: bold !important;
  background: none !important;

}

li:active .arrow {
  color: #11367d !important;
  font-weight: bold !important;
  cursor: pointer;
}

li:focus .topic-name {
  color: #11367d !important;
  cursor: pointer;
  font-weight: bold !important;
  background: none !important;

}

li:focus .arrow {
  color: #11367d !important;
  font-weight: 500 !important;
  cursor: pointer;
}


.lesson-link:hover {
  color: #007bff;
}


.lessons {

  /* padding-left: 16px !important; */
  color: #ffffff;
  cursor: grab;
  text-decoration: none;
  line-height: 30px;
  position: relative;
  left: -36px;
  margin-left: 19px;
}


.lesson-link {
  color: #333;
  text-decoration: none;
  display: block;
  cursor: pointer;
  background-color: #ffffff;

}

.lesson-link:hover {
  color: #11367d;
  cursor: pointer;
  text-decoration: underline;
}

.lesson-link.active {
  color: #11367d;
  font-weight: bold;
  /* Example additional styling */
}

.lessons li {
  text-transform: capitalize;
  list-style: none;
  color: #ffffff;
  /* background: #208f6c; */
  width: 300px;
  position: relative;
  left: -35px;
  border-radius: ;
}

.css-ahj2mt-MuiTypography-root {
  font-family: monospace !important;
}

.lessons li a {
  text-decoration: none !important;
  color: #11367d;
  word-wrap: break-word;
  padding-left: 54px;
  position: relative;
  top: 7px;
  left: 7px;
  font-size: 14px;
  max-width: 300px;
  cursor: pointer;
  font-family: monospace !important;
}

.Box {
  font-family: monospace !important;
}

.lessons hr {
  margin: 16px 0;
}

.lessons li a:hover {
  text-decoration: underline;
}

.arrow {
  margin-left: 4px;
}

.arrow {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  color: #11367d;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root .topic:hover {
  background-color: none !important;
  color: #11367d !important;
}

.horizontal {
  width: 900px;
}

.topic-name {
  color: #11367d !important;
  border-bottom: 0.01px solid #a09c9c;
  width: 100%;
  line-height: 35px;
  font-size: 14px;
  cursor: pointer;
  font-family: monospace !important;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.toggle-button {
  position: relative;
  left: 0px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.arrow-big {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent black;
  position: relative;
  transition: transform 0.3s ease;
}

.arrow.right {
  transform: rotate(-180deg);
}

@media (max-width: 992px) {
  .video-responsive {
    padding-top: 0;
  }
}

.video-responsive {
  overflow: hidden;
  position: relative;
}



/* 
  .custom-pdf-viewer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  } */

.css-1wnsr1i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}

.form-group {
  margin-bottom: 15px;
}

.form-row {
  margin-top: 50px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.quill-wrapper {
  margin-bottom: 15px;
}



.submit-button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}


.texts h2 {
  font-size: 23px;
  color: #11367d;
  text-transform: capitalize;
  white-space: pre-wrap;
  overflow: hidden;
  word-wrap: break-word;
  width: 300px;
  text-overflow: ellipsis;
  height: 90px;
}


.user-course-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}



.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.vertical {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
}




.buying-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.course-details {
  width: 100%;
  max-width: 800px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-details h2 {
  margin: 0;
  font-size: 24px;
}

.course-details p {
  margin: 10px 0;
  font-size: 16px;
}

.coupon-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.coupon-section input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  flex: 1;
}

.coupon-section button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.coupon-section button:hover {
  background-color: #0056b3;
}

.purchase-section {
  margin-top: 20px;
}

.purchase-section button {
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.purchase-section button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.purchase-section button:hover {
  background-color: #218838;
}

.optionContainer {
  padding: 30px;
  border: .5px solid #cccccc6b;
}

.optionContainer {
  display: flex;
  /* Use flexbox layout */
  flex-direction: column;
  /* Stack rows vertically */
  gap: 16px;
  /* Space between rows */
}

.option-wrapper {
  display: flex;
  /* Use flexbox layout */
  flex-wrap: wrap;
  /* Allow items to wrap onto the next line */
  gap: 16px;
  /* Space between items */
}

.option-item {
  flex: 1 1 calc(50% - 16px);
  /* Each item takes up roughly 50% of the container width minus the gap */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
  display: flex;
  /* Use flexbox for alignment */
  align-items: center;
  /* Center items vertically */
}

.optionBox {
  margin-left: 8px;
  /* Space between checkbox and label */
  flex: 1;
  /* Allow the label to take up the remaining space */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
}

.optionBox p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.search-and-category {
  float: left;
  margin-left: 181px;
}

.input-group {
  float: right;
  /* Use flexbox for row layout */
  margin-left: -65px;
}

.reward {
  background: #11367d;
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.likeButton {
  border-top: .5px solid #ddd;
  padding-top: 10px;
  border-bottom: .5px solid #ddd;
  padding-bottom: 10px;
}

.commentBox {
  border: .5px solid #ddd;
  padding: 10px;
  width: 70%;
  margin: 10px auto;
  display: block;
  align-items: center;
}

.questions p {
  margin-bottom: 1px;
  margin-top: 0;
  padding-top: 4px;
}

.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-left: 24px;
  padding-bottom: 3px;
}

/* CUSTOM*/

.custom-content p {
  margin: 10px 0;
  font-size: 19px;
  font-family: system-ui;
  text-align: justify;

}

.custom-content li {
  margin: 10px 0;
  font-size: 18px;
  font-family: system-ui;
}

.custom-content h2 {
  margin-top: 17px;
  margin-bottom: 26px;
  font-size: 36px;
  color: #000000;
  font-family: fangsong;
  text-transform: capitalize;
  font-weight: bold;
}


.custom-content h1 {
  text-align: center;
  margin-bottom: 22px;
  font-size: 48px;
  font-family: fangsong;
  font-weight: bold;
}

.custom-content h3 {
  margin-top: 20px;
  margin-bottom: 26px;
  font-size: 25px;
  color: #000000;
  font-family: fangsong;
  font-weight: bold;
}


.custom-content h4 {
  margin-top: 13px;
  margin-bottom: 18px;
  font-size: 22px;
  color: #000000;
  font-family: fangsong;
  font-weight: bold;
}

.katex-html {
  display: none;
  /* Hide HTML part */
}

.custom-content {
  display: flex;
  flex-direction: row;
  overflow-y: auto; /* Allows vertical scrolling */
  height: 100vh; /* Full viewport height */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari and Edge */
.custom-content::-webkit-scrollbar {
  display: none;
}


.cm-content {
  padding-top: 12px !important;
  /* Add top padding to the content */
}

.split-layout .html-content {
  width: 38%;
  /* Define the width explicitly */
  max-width: 38%;
  /* Ensure the width does not exceed 38% */
  overflow-y: auto;
  /* Scrollable */
  background-color: #ffffff;
  padding: 9px;
  border-radius: 8px;
  height: 100vh;
  transition: width 2s cubic-bezier(0.1, 0.7, 0.2, 2),
    opacity 2s cubic-bezier(0.1, 0.7, 0.2, 2),
    padding 0.8s cubic-bezier(0.1, 0.7, 0.2, 2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.split-layout {
  display: flex;
  justify-content: space-between;
}

.collapsed-layout .html-content {
  width: 0;
  /* Collapse the width to 0 for left-to-right effect */
  padding: 0;
  /* Remove padding when collapsed */
  opacity: 0;
  /* Fade out the content */
  transition: width 2s cubic-bezier(0.1, 0.7, 0.2, 2),
    opacity 2s cubic-bezier(0.1, 0.7, 0.2, 2),
    padding 0.8s cubic-bezier(0.1, 0.7, 0.2, 2);
  overflow: hidden;
  /* Ensure content doesn't overflow */

}

.full-layout {
  width: 100%;
}

.code-editor .h2 {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  
}

.split-layout .code-editor {
  flex: 0 0 60%; /* Fixed 60% width */
  max-width: 60%; /* Ensures it doesn’t grow beyond 60% */
  padding: 8px;
  font-size: 14px;
  height: 100vh; /* Full viewport height */
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* White background */
  overflow-y: scroll; /* Enable scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

/* Hide scrollbar in Chrome, Safari, and Edge */
.split-layout .code-editor::-webkit-scrollbar {
  display: none;
}
.collapse-button {
  position: relative;
  left: -38px;
  top: 13%;
  transform: rotate(-90deg);
  transform-origin: left top;
  padding: 1px;
  border-radius: 2px;
  background-color: #5e8eb800;
  border: solid #ffffff;
}

full-layout .html-content {
  flex: 1; /* Takes full remaining width */
  max-width: 100%; /* Ensure it can expand fully */
  padding: 15px;
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow-y: scroll; /* Scrollable when content exceeds height */
  height: 100vh; /* Full viewport height for consistency */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

/* Hide scrollbar in Chrome, Safari, and Edge */
.full-layout .html-content::-webkit-scrollbar {
  display: none;
}

.full-layout .code-editor {
  display: none;
  /* Hide the code editor if not needed */
}

/* Additional Styling */
.html-content,
.code-editor {
  height: 100%;
  /* Ensure it fills the container height */
  overflow-y: auto;
  /* Make content scrollable */
}

.html-content::-webkit-scrollbar,
.code-editor::-webkit-scrollbar {
  display: none;
  /* Width of the scrollbar */
}

.html-content::-webkit-scrollbar-thumb,
.code-editor::-webkit-scrollbar-thumb {
  display: none;

}

.html-content::-webkit-scrollbar-thumb:hover,
.code-editor::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
  /* Hover color of the scrollbar */
}

.custom-content .hljs-type,
.hljs-string,
.hljs-number,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: #7278c4;
}

.code-editor-container .ql-syntax {
  background-color: #3a3939;
  color: #000000;
  padding: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
  display: block;
  border-radius: 0;
  margin-bottom: 10px;
}


pre code.hljs {
  background: #23241f;
  color: #f8f8f2;
}

.custom-content .ql-indent-1 {
  margin-left: 30px;
}

.custom-content .ql-indent-2 {
  margin-left: 45px;
}

.custom-content .ql-indent-3 {
  margin-left: 45px;
}

.css-bny0f1-MuiCardContent-root {
  padding: 0px !important;
}

.custom-content .hljs-title,
.hljs-section {
  color: #0cd25d;
  font-weight: bold;
}

.custom-content .hljs-built_in,
.hljs-bullet,
.hljs-code,
.hljs-addition {
  color: #039cdc;
}

.custom-content img {

  max-width: 700px;
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
  /* Centers the image */
}


.custom-content .ql-video {
  max-width: 600px;
  max-height: 400px;
  width: 550px;
  height: 350px;
  align-items: center;
  display: block;
  margin: 0 auto;
  /* Centers the image */
}

.custom-content code {
  padding: 3px;
  background-color: #f4f4f4;
  /* border-radius: 0px; */
  font-size: 17px;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-btn {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.preview-content {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  max-height: 400px;
  overflow-y: auto;
}

.code-editor {
  border-left: 1px solid #ddd;
  padding: 10px;
}


.result-code {
  background-color: #1a1818;
  color: #d2d2d2;
  padding: 16px;
  border-radius: 4px;
  overflow: auto;
  max-width: 805px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block;
}


/* Fixed position for the next button */
.next-lesson-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  z-index: 1000;
  /* Ensure the button is above other content */
}


.content-wrapper.blurred {
  filter: blur(5px); /* Apply blur */
  pointer-events: none; /* Disable interactions */
  position: relative;
}

.content-wrapper.blurred::before {
  content: "You are not enrolled";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent overlay */
  color: #000;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Overlay above everything */
}
