.search-control-home {
    display: block;
    /* border-bottom-left-radius: 18px; */
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 29px;
    transition: border-color .15sease-in-out, box-shadow .15sease-in-out;
}