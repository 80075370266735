
body {
    display: flex; 
    flex-direction: column; 
    min-height: 100vh; 
    margin: 0; 
}

footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-top: 1px solid #e7e7e7;
    margin-top: auto; 
}

footer img {
    height: 100px;
}

footer ul {
    list-style-type: none;
    display: flex;
    gap: 15px; 
    padding: 0;
}

footer a {
    text-decoration: none;
    color: #11367D; 
    font-size: 14px; 
}
