.container-fluid-order-summary {
  padding-top: 10vh;
  width: 75%;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;    
  margin: 0 auto;      
}

.order-summary-container {
    width: 60%;
    max-width: 800px;
    margin: 17px 400px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .order-summary-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .order-item, .order-discount, .order-total {
        margin-bottom: 20px;
        justify-content: right;
  }
  
  .order-item .total-line, .order-discount .discount-line, .order-total .total-line {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .order-item .total-line span, .order-discount .discount-line span, .order-total .total-line span {
    font-size: 16px;
    color: #333;
    align-content: center;
  }
  
  .order-discount {
    padding: '2px';
  }
  
  .apply-coupon-button {
    padding: 10px 20px;
    border-radius: 4px;
    background-color: #4e43e3; 
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border: none; 
    transition: background-color 0.3s, box-shadow 0.3s;
  }


  .order-discount input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    width: 150px;
    margin-right: 7px;
  }
  
  .coupon-section button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .coupon-section button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .complete-purchase-button {
    width: 80% !important;
    padding: 4px;
    background-color: #1d9b6f;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    height: 50px;
    margin-top: 30px;
    position: relative;
  }
  
  .complete-purchase-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .complete-purchase-button:hover:not(:disabled) {
    background-color: #218838;
  }
  
  .cancel-coupon-button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
  }

  .phone-number-section {
    margin: 1em 0;
}

.phone-number-section input {
    width: 36%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.phone-number-section input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
} 