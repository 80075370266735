/* Center the table */
.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff ;/* Light background */
    margin-top: 5px;
  }
  
  /* Style the table */
  .custom-table {
    width: 75%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: 90px;
  }
  
  /* Style table cells */
  .custom-table td {
    padding: 15px;
    text-align: center;
    font-size: 18px;
    position: relative;
  }
  
  /* Add hover effect */
  .custom-table tr:hover {
    background-color: #ffffff;
  }
  .custom-table td:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10%;
    height: 60%;
    width: 1px;
    background-color: #ddd; /* Light gray border */
  }