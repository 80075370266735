.controls-container {
    display: flex;
    justify-content: space-between; /* Pushes items to opposite ends */
    align-items: center; /* Vertically aligns items */
    margin-bottom: 10px;
  }
  
  .zoom-button {
    color: rgb(39, 33, 33);
    border: none;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s;
    background: transparent;
    /* margin-top: 5px; */
}
  
  .zoom-button:hover {
    color: cadetblue;
    zoom: normal;
  }

  .tooltip {
    display: none;
    position: fixed; /* Use fixed to position relative to the viewport */
    top: 10px;        /* Adjust to control vertical position */
    right: 10px;      /* Adjust to control horizontal position */
    background: #333;
    color: #fff;       /* Changed to white for better contrast */
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;     /* Ensure it's on top of other elements */
    pointer-events: none; /* Prevents tooltip from blocking clicks */
  }
  
  /* Show tooltip on hover */
  .zoom-button:hover .tooltip {
    display: block;
  }

  .dotted-bracket {
    font-size: 13px;
    font-weight: bold;
    border: 2px dotted #333;
    padding: 1px;
    border-radius: 4px;
    display: inline-block;
    background: #e0e0e0;
    transition: background 0.3s ease;
  }
  
  .dotted-bracket:hover {
    background: #d0d0d0;
  }