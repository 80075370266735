.terms-container {
    max-width: 800px; 
    margin: 20px auto; 
    padding: 20px; 
    border: 1px solid #e0e0e0; 
    border-radius: 8px; 
    background: #fff; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.section {
    margin-bottom: 20px;
}

.section h4 {
    margin-top: 10px; 
}

.section p {
    text-align: justify; 
    margin: 5px 0; 
}

footer {
    text-align: center;
    margin-top: 30px; 
}
