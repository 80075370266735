/* SuccessPopup.css */
.success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }
  
  .success-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .success-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .success-message {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ok-button {
    background-color: #155724;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .ok-button:hover {
    background-color: #0c5460;
  }
  