.video-player-container {
    position: relative;
    width: 30%;
  }
  
  .video-player {
    width: 100%;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 0px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  
  .video-thumbnail {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .thumbnail img {
    background-attachment:fixed;
    background-repeat: no-repeat;
    background-size: cover; 
    width: 100%;
  }
  
  .video-container {
    display: block;
    width: 80%;
    height: 100%;
    grid-row: 1; 
    position: relative; 
  }
  
  .thumbnail {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    padding: 5px;
}

.placeholder-thumbnail {
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.enroll-container {
  grid-row: 2; 
  padding: 3px; 
  display: block;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50px;
  width:77% ;
  background: #f8f9fa;
  height: 79px;
  border-radius: .2px solid;
}

  .play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .play-button-preview {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid blanchedalmond;
    cursor: pointer;
  }
  