.course-name {
    color: rgb(22, 23, 23) ;
    letter-spacing: normal;
    font-family: 'Arial', sans-serif; 
    font-size: 38px; 
    text-transform: capitalize;
    margin-bottom: 22px;
}

.tabs-container {
    margin-top: 20px;
    width: 75%;  /* Set width to 60% */
    margin-left: auto;
    margin-right: auto; /* Center the container */
    text-align: center;
    border-bottom: 2px solid #ddd; /* Thin line under tabs */
}

.tabs {
    display: flex;
    gap: 20px;
}

.tabs button {
    background: none;
    border: none;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: 0.3s;
}

.tabs button.active {
    border-bottom: 3px solid #11367d; /* Highlight the active tab */
    font-weight: bold;
}

.tab-content {
    padding-top: 10px;
    margin-top: 10px;
}
