.lock-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(245, 245, 245, 0.6); /* Semi-transparent dark overlay */
    color: #fff; /* White text for contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    flex-direction: column; 
    border-radius: 10px;
    gap: 10px;    
    box-shadow: 0 4px 8px rgba(137, 162, 238, 0.2); /* Subtle shadow */
    transition: opacity 0.3s ease-in-out; /* Smooth fade effect */
  }
  
  .lock-icon {
    font-size: 100px;             /* Icon size */
    color: #3b4e83;              /* Red color for the lock */
   
  }
  
  .lock-message {
    font-size: 18px;
    font-weight: bold;
    color: #f26990;
    margin: 0;                   /* Removes any default margin */
    margin-bottom: 20%
  }