/* Container Styling */
.less-about-container {
    width: 70%;
    margin: 40px auto;
    text-align: center;
  }
  
  /* Section Headers */
  .section-title {
    font-size: 18px;
    color: #121416;
    margin-bottom: 4px;
    /* border-bottom: 2px solid #ddd; */
    display: flex
;
    padding-bottom: 5px;
    align-items: flex-start;
    justify-content: left;
    font-weight: bold;
}
  
  /* What You'll Learn Section */
  .learn-items {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .column {
    width: 48%;
    text-align: left;
  }
  
  .column p {
    font-size: 15px;
    color: #333;
    padding: 8px 0;
  }
  
  .column p::before {
    content: "✔";
    color: #11367d;
    font-weight: bold;
    margin-right: 10px;
  }
  
  /* Details to Know Section */
  /* Details to Know - Three Column Layout */
.details-grid {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: center;
  }
  
  .detail-box {
    width: 30%;
    background: #ffffff;
    border-radius: 5px;
    display: grid;
    grid-template-rows: 1fr 1fr; /* Two equal rows */
    align-items: start;
    justify-content: start;
    height: 80px; /* Adjust as needed */
  }
  
  /* Label (strong) - First Row */
  .detail-box strong {
    font-size: 16px;
    color: #11367d;
    font-weight: bold;
    display: block;
    text-align: center;
  }
  
  /* Value (p) - Second Row */
  .detail-box p {
    font-size: 14px;
    color: #555;
    font-weight: bold;
    margin: 0;
    text-align: left;
  }
  