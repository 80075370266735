/* src/MyProfile.css */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .profile-card {
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #fff;
    width: 50%;
    position: absolute;
    top: 120px;
  }
  
  .user-icon {
    color: #007bff;
  }
  
  .profile-info {
    margin-top: 20px;
    text-align: justify;
  }
  
  .profile-info-row {
    align-items: center;
    margin-bottom: 10px;
  }
  
  .profile-info-edit {
    display: flex;
    align-items: center;
    margin-left: 250px;
  }
  
  .profile-info-edit input {
    padding: 10px;
    margin-right: 3px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 306px;
  }
  
  .save-icon, .edit-icon {
    cursor: pointer;
    color: #007bff;
  }
  
  .save-icon {
    margin-left: 10px;
  }
  
  .edit-icon {
    margin-left: 10px;
  }
  
  .save-icon:hover, .edit-icon:hover {
    color: #0056b3;
  }
  