.post p.info {
    margin: -10px 0;
    color: #888;
    font-size: 1rem;
    display: flex;
    gap: 10px;
    text-transform: capitalize;
}

.post p.info a.author {
    color: #333;
}

.post p.summary {
    margin: 10px 0;
    line-height: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3.6rem;
    color: #05036b;
    text-align: justify;
}

.blog-index-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 20px;
}

.posts-grid {
    display: grid; 
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; 
}
.post-item {
    border: 1px solid #e0e0e0; 
    border-radius: 8px; 
    overflow: hidden; 
    background-color: #fff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}



.post-page-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 10%;
}

.post-content img {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain; /* Ensures the image fits within the container */
}

.post-content h2 {
    margin-bottom: 22px;
    font-size: 35px;
    padding: -42px;
    color: #11367d;
    text-transform: capitalize;
}

.post-content dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: system-ui;
}

.post-content p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 18px;
    font-family: system-ui;
}

.ql-syntax {
    background-color: #1e1e1e; 
    color: #dcdcdc; 
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.post-image {
    width: 80%;
    max-width: 80%;
    padding-top: 50%;
    position: relative;
    overflow: hidden;
}

.googleAdSense{
    margin-top: 16px;
    height: 250px;
    width: 970px;
    display: block;
}


.post-content, .post-details {
    max-width: 100%;
    overflow: hidden; 
    word-wrap: break-word; 
}

.post-details {
    img {
        max-width: 100%;
        height: auto;
        display: block;
        transition: transform 0.3s ease-in-out; 
        cursor: zoom-in; 
    }
}


.post-image {
    width: 100%;
    max-width: 100%;
    padding-top: 50%;
    position: relative;
    overflow: hidden;
}

.post-image img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

/* Add classes to simulate zooming in on specific parts of the image */
.post-details img.zoom-top-left {
    transform-origin: top left;
    transform: scale(1.5);
    cursor: zoom-out;
}

.post-details img.zoom-center {
    transform-origin: center center;
    transform: scale(1.5);
    cursor: zoom-out;
}


.texts {
    padding: 4px;
  }

.post-title {
    font-size: 2rem;
    margin-bottom: 10px;
}



.post-image-list {
    width: 100%;
    max-width: 100%;
    height: 140px;
    position: relative;
    overflow: hidden;
}

.texts h3 {
    font-size: 20px;
    color: #11367d;
    text-transform: capitalize;
    white-space: pre-wrap;
    overflow: hidden;
    word-wrap: break-word;
    width: 300px;
    text-overflow: ellipsis;
    height: 90px;
  }



.post-image-list img {
    width: 100%;
    height: 20%;
    top: 0;
    left: 0;
}

.post-summary {
    line-height: 1.8rem;
    margin-top: 20px;
}

.form-group1 {
    margin-bottom: 15px;
  }

  .button-container1 {
    display: flex;
    margin-top: 81px;
    justify-content: center;
    align-items: center;
    height: 100%;
    
  }
.create-post-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .create-post-form1 {
    width: 90%;
    max-width: 800px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }

  .create-post-container1 h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }


@media (max-width: 800px) {
    .post-content {
        flex-direction: column;
    }
}

