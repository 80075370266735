.thank-you-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px #0000001a;
  margin: 39px 500px 40px auto;
  max-width: 600px;
  padding: 20px;
  text-align: center;
  
}
  
  .thank-you-container h1 {
    color: #4caf50; 
    margin-bottom: 20px;
  }
  
  .thank-you-container p {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .continue-shopping-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .continue-shopping-button:hover {
    background-color: #45a049;
  }
  